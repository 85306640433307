import React, { useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from 'react-router-dom'
import { UserContext } from '../../components/context/UserContext'
import * as matrixcs from "matrix-js-sdk";

const myUserId = localStorage.getItem("mx_user_id");
const myAccessToken = localStorage.getItem("mx_access_token");
const matrixClient = matrixcs.createClient({
  baseUrl: "https://treff.klasseklima.org",
  accessToken: myAccessToken,
  userId: myUserId,
  useAuthorizationHeader: true
});

const Login = () => {
  const { register, handleSubmit, errors } = useForm();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const { setUser } = useContext(UserContext);

  const onSubmit = async () => {
    const data = { "type": "m.login.password", "user": name, "password": password }

    try {
      const sendMessage = await matrixClient.login("m.login.password", data);
      const res = await sendMessage;
      localStorage.setItem('mx_access_token', res.access_token);
      localStorage.setItem('mx_home_server', res.home_server);
      localStorage.setItem('mx_hs_url', "https://treff." + res.home_server);
      localStorage.setItem('mx_user_id', res.user_id);
      localStorage.setItem('mx_device_id', res.device_id);
      setUser(res.user_id);
      //history.push('/dashboard')
      history.push('/dashboard')
      return (window.location.reload(false))
    }
    catch (e) {
      alert(e.data.error)
      return <Redirect to='/login' />
    }
  }

  const changeName = e => setName(e.target.value);
  const changePassword = e => setPassword(e.target.value);

  return (
    < section id="login" >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="username">username:</label>
          <input name="username" type="text" placeholder="u.name" value={name} onChange={changeName} ref={register({ required: true })} />
        </div>
        {errors.username && "Username can't be empty."}
        <div>
          <label htmlFor="password">password:</label>
          <input name="password" type="password" placeholder="" value={password} onChange={changePassword} ref={register({ required: true })} />
        </div>
        {errors.password && "Password can't be empty."}
        <button name="submit" type="submit">LOGIN</button>
      </form>
      <ul>
        <li><a href="mailto:robert@klasseklima.org?subject=treffpunkt-klasseklima/help" rel="external noopener noreferrer">I cannot log in!</a></li>
      </ul>
    </section >
  );
}

export default Login;