import React from 'react';
import udk from '../../assets/img/udk-berlin.svg'; // with import
import { useTranslation } from 'react-i18next';
/*
import style from './style.css';
*/

const Footer = () => {
  const { t, i18n } = useTranslation(['translation', 'welcome']);

  const changeLanguage = code => {
    localStorage.setItem('cr_lang', code);
    i18n.changeLanguage(code);
  };

  return (
    <footer>
      
      <div className="l10n">
        <p className="copyright">&#x1f12f; 2020 <a href="https://klasseklima.org" rel="nofollow noopener noreferrer"><strong>Klasseklima</strong></a></p>
        <button onClick={() => changeLanguage("de")}>
          {t("translation:de")}
        </button>
        <span>/</span>
        <button onClick={() => changeLanguage("en")}>
          {t("translation:en")}
        </button>
      </div>
      <p className="copyright"> <a href="https://medienhaus.udk-berlin.de" rel="nofollow noopener noreferrer"><strong>medienhaus/</strong></a></p>

    </footer>
  );
}

export default Footer;
