import React from 'react'
import { useTranslation, Trans } from 'react-i18next';

const Landing = () => {
  const { t } = useTranslation(['translation', 'landing']);

  return (
    <>
      <section id="definition">
        <blockquote><Trans i18nKey="landing:definition"><em>classroom, n.</em> — shared space to <strong>communicate</strong> and exchange, <strong>meet</strong> face to face, <strong>present</strong> to each other, and <strong>study</strong> together.</Trans></blockquote>
      </section>
     
    </>
  )
}


export default Landing;