import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { Loading } from './components/loading/'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
